class GridRowBalancer {
  constructor({ widgetSelector, cardSelector }) {
    this.cardWidgets = document.querySelectorAll(widgetSelector);
    this.cardSelector = cardSelector;
  }

  static centerLastRow(widget, cardItems) {
    const computedStyle = getComputedStyle(widget);
    const columns = computedStyle.gridTemplateColumns.split(' ').length;
    const totalItems = cardItems.length;
    const itemsInLastRow = totalItems % columns || columns;

    cardItems.forEach((item) => {
      const currentItem = item;

      currentItem.style.transform = 'translateX(0)';
    });

    if (itemsInLastRow < columns) {
      const [cardItemWidth, gap] = [
        cardItems[0].offsetWidth,
        parseFloat(computedStyle.gap),
      ];

      const totalSpace = (columns - itemsInLastRow) * (cardItemWidth + gap);
      const offset = Math.round(totalSpace / 2);

      Array.from(cardItems)
        .slice(-itemsInLastRow)
        .forEach((item) => {
          const currentItem = item;

          currentItem.style.transform = `translateX(${offset}px)`;
        });
    }
  }

  init() {
    const prevWidths = new WeakMap();

    this.cardWidgets.forEach((widget) => {
      const cardItems = widget.querySelectorAll(this.cardSelector);

      if (cardItems.length === 0) return;

      prevWidths.set(widget, widget.clientWidth);

      const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const prevWidth = prevWidths.get(entry.target);

          if (entry.contentRect.width !== prevWidth) {
            prevWidths.set(entry.target, entry.contentRect.width);

            requestAnimationFrame(() => GridRowBalancer.centerLastRow(widget, cardItems));
          }
        });
      });

      GridRowBalancer.centerLastRow(widget, cardItems);
      resizeObserver.observe(widget);
    });
  }
}

export default GridRowBalancer;
