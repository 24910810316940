import GridRowBalancer from './utils/GridRowBalancer';
import CardSectionCounter from './utils/CardSectionCounter';

const App = {
  gridRowBalancer: new GridRowBalancer({ widgetSelector: '.cards-widget', cardSelector: '.card-item' }),
  cardSectionCounter: new CardSectionCounter(),

  async init() {
    this.gridRowBalancer.init();
    this.cardSectionCounter.init();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
